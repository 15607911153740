import TYPES from '@/types';

import Vue from 'vue';
import { v4 } from 'uuid';

import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import { AgreementEntity } from '@/modules/agreements/domain/entities/agreement-entity';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InvestmentStrategyResultViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  private readonly search_agreements_query!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetimeValue!: DatetimeValue;

  readonly i18n_namespace = 'components.allianz-dashboard.investment-strategy-result';

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly view: Vue;

  readonly agreement_hire_moderate_name = 'permission_activate_sowos_moderated_allianz';

  is_valid_form = false;

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
  }

  inputs_rules = {
    accept_agreement: [
      requiredRule,
    ],
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get can_continue() {
    return this.is_valid_form;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep', { customer_agreement: this.state.customer_agreement });
  }

  loadAgreement = async (): Promise<AgreementEntity | undefined> => {
    const agreements = await this.search_agreements_query.execute();
    const agreement_hire_moderate = agreements.find(
      (agreement) => agreement.name === this.agreement_hire_moderate_name,
    );
    return agreement_hire_moderate;
  }

  acceptAgreement = async () => {
    const agreement_hire_moderate = await this.loadAgreement();

    if (agreement_hire_moderate && !this.state.customer_agreement.id) {
      this.state.customer_agreement.id = v4();
      this.state.customer_agreement.customer_id = this.customer_id || '';
      this.state.customer_agreement.accepted_on = this.datetimeValue.create().toString();
      this.state.customer_agreement.agreement_type_id = agreement_hire_moderate.id;
    }
  }
}
