
















































































import { Vue, Component } from 'vue-property-decorator';
import InvestmentStrategyResultViewModel
  from
  '@/vue-app/view-models/allianz-dashboard/access-more-strategies/investment-strategy-result-view-model';

@Component({
  name: 'InvestmentStrategyResult',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class InvestmentStrategyResult extends Vue {
  investment_strategy_result_view_model = Vue.observable(
    new InvestmentStrategyResultViewModel(this),
  );
}

